import request from "@/utils/request";

// 收运基本信息
export function info(collectorCode) {
  return request({
    url: "/bi/collector/detail/info/" + collectorCode,
    method: "GET"
  });
}

// 查询条件-人员
export function condition(collectorCode, query) {
  return request({
    url: "/bi/collector/detail/collect/list/condition/" + collectorCode,
    method: "GET",
    params: query
  });
}

// 收运详情-列表
export function list(collectorCode, query) {
  return request({
    url: "/bi/collector/detail/collect/list/" + collectorCode,
    method: "GET",
    params: query
  });
}

// 送厂详情
export function factoryList(collectorCode, query) {
  return request({
    url: "/bi/collector/detail/factory/list/" + collectorCode,
    method: "GET",
    params: query
  });
}

// 收运概览
export function overview(collectorCode, query) {
  return request({
    url: "/bi/collector/detail/collect/overview/" + collectorCode,
    method: "GET",
    params: query
  });
}
